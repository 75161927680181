import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  scope: 'admin',
  platform: 'web',

  appVersion: '1.2.0',

  apiBaseUrl: 'https://api.bg.tellibus.com/v1',
  imageBaseUrl: 'https://shared.bg.tellibus.com/',
  socketIoUrl: 'https://api.bg.tellibus.com',

  apiPageSize: 10,

  jwt: { refreshTokenNonce: 'TzNYYsG1eo' },

  publicUrl: 'supervisor.bg.tellibus.com',
};
